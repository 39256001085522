export const RWDOMParser = DOMParser;

/**
 * Extracts the HTML string from a DOM element returned by the RWDOMParser
 *
 * The RWDOMParser returns a DOM element that either implements the native HTMLElement
 * on web or its own implementation on other platforms. This function ensures that
 * the HTML string is extracted in a platform agnostic way.
 */
export function extractHtmlStringFromDomElement(element: HTMLElement): string {
  return element.outerHTML;
}
