import { isHTMLElement } from '../../typeValidators';
import getFirstDescendantWith from './getFirstDescendantWith';
import getSiblings from './getSiblings';

/*
  Gets the next node (satisfying the optional matcher function argument). The direction can also be customized.

  1. Look at next sibling.
  2. Look at parent's next sibling.
  3. Look at that aunt's descendents.
  4. And so on.
*/
const getNextElementWithinContainer = ({
  container,
  direction = 'next',
  element,
  matcher: matcherArgument = () => true,
  checkParents = false,
}: {
  container: Element;
  direction: 'next' | 'previous';
  element: Element;
  matcher?: (element: Element) => boolean;
  checkParents?: boolean;
}): Element | undefined => {
  if (!container || !element) {
    return;
  }
  if (!isHTMLElement(element)) {
    throw new Error('element must be an element (not a node, etc.)');
  }
  if (!container.contains(element)) {
    return;
  }

  // Add container check
  const matcher = (otherElement: Element) =>
    container.contains(otherElement) && matcherArgument(otherElement);

  for (const sibling of getSiblings({ direction, element }).siblings) {
    const siblingElement = sibling as Element;
    const descendant = matcher(siblingElement)
      ? sibling
      : getFirstDescendantWith(siblingElement, matcher, direction === 'next' ? 'forward' : 'reverse');
    if (descendant) {
      return descendant;
    }
  }

  if (!element.parentElement) {
    return;
  }

  // TODO: ask Adam if this will break stuff
  if (checkParents && matcher(element.parentElement)) {
    return element.parentElement;
  }

  return getNextElementWithinContainer({
    container,
    direction,
    element: element.parentElement,
    matcher,
    checkParents,
  });
};

export default getNextElementWithinContainer;
