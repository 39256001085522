import { LenientWindow } from '../../types/LenientWindow';

function getDistanceFromCenter(element: Element | Range, windowParam: LenientWindow) {
  const { top, left, width, height } = element.getBoundingClientRect();
  const viewportWidth = windowParam.innerWidth;
  const viewportHeight = windowParam.innerHeight;
  const elementCenterX = left + width / 2;
  const elementCenterY = top + height / 2;
  const distanceX = Math.abs(viewportWidth / 2 - elementCenterX);
  const distanceY = Math.abs(viewportHeight / 2 - elementCenterY);

  // You can use the distance calculation method as per your requirement.
  // For example, you can use the sum of distances or any other metric.

  return Math.sqrt(distanceX ** 2 + distanceY ** 2); // Euclidean distance
}
export function findMostCenteredElement<T extends Element | Range>(
  elements: T[] | HTMLCollection,
  windowParam: LenientWindow,
): T | null {
  if (elements.length === 0) {
    return null;
  }
  let mostCenteredElement = elements[0];
  let minDistance = getDistanceFromCenter(mostCenteredElement, windowParam);

  for (const element of elements) {
    const currentDistance = getDistanceFromCenter(element, windowParam);
    if (currentDistance < minDistance) {
      minDistance = currentDistance;
      mostCenteredElement = element;
    }
  }
  return mostCenteredElement as T;
}
function isSomewhatVisible(element: Element | Range, rect: Rect): boolean {
  if (element instanceof Element && blacklistedElements.has(element.nodeName)) {
    return false;
  }
  // Is the item visible in the viewport at all
  // This means that the item can have a bit of it in the viewport from the top, or bottom
  const bounding = element.getBoundingClientRect();
  // Do this inversely; I.E figure out if the item is too high or too low
  const tooHigh = bounding.bottom < rect.top;
  const tooLow = bounding.top > rect.bottom;
  return !tooHigh && !tooLow;
}

const blacklistedElements = new Set(['STYLE', 'HEAD', 'BODY', 'SCRIPT', 'LINK']);
type Rect = { left: number; top: number; bottom: number; right: number; };

export function getVisibleElementsWithinRectBounds<T extends (Element | Range)>(
  elements: T[],
  boundingRect: Rect): T[] {
  if (elements.length === 0) {
    return [];
  }
  let somewhatVisibleElement: T | null = null;
  let foundIndex = 0;
  let left = 0;
  let right = elements.length - 1;
  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const middleElement = elements[mid];
    if (isSomewhatVisible(middleElement, boundingRect)) {
      somewhatVisibleElement = middleElement as T;
      foundIndex = mid;
      break;
    }
    const bounding = middleElement.getBoundingClientRect();
    const tooHigh = bounding.bottom < boundingRect.top;
    const tooLow = bounding.top > boundingRect.bottom;
    if (tooHigh) {
      // this element is too high, search all items below it
      left = mid + 1;
    } else if (tooLow) {
      // this element is below the ycoord
      right = mid - 1;
    } else {
      // This case wouldn't make sense but just in case so we dont infinite loop
      // if its not too high and not too low, it should be visible
      break;
    }
  }
  // Go up until we no longer have a visible Element
  while (
    somewhatVisibleElement &&
    isSomewhatVisible(somewhatVisibleElement, boundingRect) &&
    foundIndex > 0
  ) {
    if (foundIndex - 1 < 0 || !isSomewhatVisible(elements[foundIndex - 1], boundingRect)) {
      break;
    }
    foundIndex -= 1;
    somewhatVisibleElement = elements[foundIndex];
  }

  // now starting at that index, go through all the elements until we no longer have a visible one
  const visibleElements: T[] = [];
  while (foundIndex < elements.length) {
    somewhatVisibleElement = elements[foundIndex];
    if (!isSomewhatVisible(somewhatVisibleElement, boundingRect)) {
      break;
    }
    visibleElements.push(somewhatVisibleElement);
    foundIndex += 1;
  }

  return visibleElements;
}

export function areTwoRectsIntersecting(rect1: Rect, rect2: Rect) {
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
}

export function isIntersecting(rect: Rect, element: Element) {
  const rect2 = element.getBoundingClientRect();

  return areTwoRectsIntersecting(rect, rect2);
}
export function findCenteredElementInViewport<T extends Element | Range>(
  validElements: T[],
  windowParam: LenientWindow,
): T | null {
  const viewportHeight = windowParam.innerHeight;
  const viewportWidth = windowParam.innerWidth;
  const boundingRect = { top: 0, bottom: viewportHeight, left: 0, right: viewportWidth };
  const visibleElements = getVisibleElementsWithinRectBounds(validElements, boundingRect);
  return findMostCenteredElement<T>(visibleElements, windowParam);
}
