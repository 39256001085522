import React, { Suspense, useCallback, useEffect, useMemo } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
// eslint-disable-next-line no-restricted-imports
import { useHistory, useLocation, useParams } from 'react-router-dom';
import background from 'shared/foreground/portalGates/toBackground';
import { useFocusedDocumentId } from 'shared/foreground/stateHooks/useFocusedDocument';
import { setFocusedDocumentId } from 'shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { SEARCH_DEFAULT_PAGE_SIZE, useDocumentSearch } from 'shared/foreground/useDocumentSearch';
import isComposing from 'shared/foreground/utils/isComposing';
import useStatePlusLiveValueRef from 'shared/foreground/utils/useStatePlusLiveValueRef';
import { ShortcutId } from 'shared/types/keyboardShortcuts';

import { useIsRightSidebarHidden } from '../../hooks/hooks';
import { useKeyboardShortcutPreventDefault } from '../../hooks/useKeyboardShortcut';
import { toggleHideRightSidebar } from '../../stateUpdaters/sidebars';
import { reactLazy } from '../../utils/dynamicImport';
import { useShortcutsMap } from '../../utils/shortcuts';
import { FloatingPill } from '../FloatingPill';
import ToggleRightPanelIcon from '../icons/ToggleRightPanelIcon';
import { ShowNavigationLeftPanelButton } from '../ShowNavigationLeftPanelButton';
import StandardPage from '../StandardPage';
import Tooltip from '../Tooltip';
import styles from './SearchPage.module.css';

const Documents = reactLazy(() => import('../Documents'));

export const ToggleShowPannelsButton = (): JSX.Element => {
  const shortcutsMap = useShortcutsMap();
  const shortcut = shortcutsMap[ShortcutId.HideRightPanel];

  return (
    <Tooltip content="Show right panel" shortcut={shortcut}>
      <button
        className={[styles.actionButton, styles.hidePanelsButton].join(' ')}
        tabIndex={-1}
        onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          toggleHideRightSidebar({ userInteraction: 'click' });
        }}
        type="button"
      >
        <ToggleRightPanelIcon />
      </button>
    </Tooltip>
  );
};

export const SearchPage = React.memo(function SearchPage() {
  const { openDocumentId } = useParams<{ openDocumentId?: string; }>();
  const focusedDocId = useFocusedDocumentId();
  const history = useHistory();

  // search
  const { search } = useLocation();
  const initialSearchQuery = useMemo(() => new URLSearchParams(search).get('q') ?? '', [search]);
  const {
    searchResult,
    searchQuery,
    setSearchQuery,
    executedSearchQuery,
    loadMoreResultRows,
    contentIndexingMessage,
  } = useDocumentSearch(initialSearchQuery);

  useEffect(() => {
    // replace search param in browser history
    const param = executedSearchQuery.length > 0 ? `?q=${encodeURIComponent(executedSearchQuery)}` : '';
    history.replace({ search: param });
  }, [history, executedSearchQuery]);

  useEffect(() => {
    // Trigger all document content to be loaded if it hasn't been yet
    background.loadDocumentContentInBackground();
  }, []);

  useEffect(() => {
    if (openDocumentId) {
      return;
    }
    if (!focusedDocId && searchResult?.rows[0]) {
      setFocusedDocumentId(searchResult.rows[0].doc_id, { userInteraction: null });
    }
    if (focusedDocId && !searchResult?.rows.length) {
      setFocusedDocumentId(null, { userInteraction: null });
    }
  }, [openDocumentId, focusedDocId, searchResult?.rows]);

  const contentIndexingIndicator = contentIndexingMessage
    ? <div className={styles.contentIndexingIndicator}>
      <p>{contentIndexingMessage}</p>
    </div>
   : null;

  let noResultsMessage;
  if (!searchResult || searchResult.totalCount === 0) {
    const noResultsText = searchQuery ? 'No results' : '';
    noResultsMessage = noResultsText ? <p id="no-search-results">{noResultsText}</p> : null;
  }

  const listedDocumentIds = useMemo(
    () => searchResult?.rows.map((row) => row.doc_id) ?? [],
    [searchResult?.rows],
  );

  return (
    <StandardPage isSidebarHidden={Boolean(openDocumentId)}>
      <div className={`${styles.searchContainer} ${openDocumentId ? styles.hasOpenedDoc : ''}`}>
        {!openDocumentId &&
          <>
            <Header query={searchQuery} setQuery={setSearchQuery} />
            {(noResultsMessage || contentIndexingIndicator) &&
              <div className={styles.messageWrapper}>
                {contentIndexingIndicator}
                {noResultsMessage}
              </div>
            }
          </>
        }

        <Suspense fallback={null}>
          <Documents
            documentPathPrefix="/search"
            listedDocumentIds={listedDocumentIds}
            openDocumentId={openDocumentId}
            pageSize={SEARCH_DEFAULT_PAGE_SIZE}
            parentPath="/search"
            showSearchMatches
            onEndThresholdReached={loadMoreResultRows}
          />
        </Suspense>
        <FloatingPill>
          <>Count: {searchResult?.totalCount ?? '-'}</>
        </FloatingPill>
      </div>
    </StandardPage>
  );
});

const Header = React.memo(function Header({
  query,
  setQuery,
}: { query: string; setQuery: (q: string) => void; }) {
  const rightSidebarHidden = useIsRightSidebarHidden();
  const history = useHistory();
  const searchPlaceholder = 'Search your Library...';
  const shortcutsMap = useShortcutsMap();

  const [searchInput, setSearchInput, searchInputRef] = useStatePlusLiveValueRef<HTMLElement | null>(
    null,
  );
  const focusField = useCallback(() => searchInputRef.current?.focus(), [searchInputRef]);

  useEffect(() => {
    (async () => {
      if (!searchInput) {
        return;
      }
      focusField();
    })();
  }, [focusField, searchInput]);
  useKeyboardShortcutPreventDefault(shortcutsMap[ShortcutId.Search], focusField);

  return (
    <>
      <div className={styles.searchWrapper}>
        <div className={styles.headerLeft}>
          <ShowNavigationLeftPanelButton className={styles.showNavigationSidebarButton} />
        </div>
        <div className={styles.inputWrapper}>
          <IoSearchOutline size={20} color="#000" className={styles.searchIcon} />
          <input
            aria-labelledby="search-label"
            autoFocus
            id="search-field"
            ref={setSearchInput}
            autoComplete="off"
            type="search"
            className={styles.searchInput}
            placeholder={searchPlaceholder}
            value={query}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (isComposing(event)) {
                return;
              }
              if (event.key === 'Escape') {
                history.push(`/`);
              } else if (event.key === 'Enter' || event.keyCode === 40) {
                (event.target as HTMLInputElement).blur();
              }
            }}
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>
        <div className={styles.headerRight}>{rightSidebarHidden && <ToggleShowPannelsButton />}</div>
      </div>
    </>
  );
});
