import { isChunkContainer, isHTMLElement } from '../../typeValidators';
import exceptionHandler from '../../utils/exceptionHandler.platform';
// eslint-disable-next-line import/no-cycle
import { forceContentLoadForChunk } from '../contentFramePortalGateInternalMethods';
import type { ChunkContainerElement } from '../types/chunkedDocuments';

export function findScrollTargetInChunkContainer(container: ChunkContainerElement): HTMLElement {
  // element we want to scroll to is itself a chunk container, which isn't a valid scroll target.
  // therefore we grab the first element inside the container as a scroll target.
  if (!isHTMLElement(container.firstElementChild)) {
    exceptionHandler.captureException('ScrollingManager: chunk container has no child element to scroll to', {
      extra: {
        dataset: container.dataset,
      },
    });
    return container;
  }
  return container.firstElementChild;
}

export async function forceChunkContentLoadForContainer(container: ChunkContainerElement): Promise<void> {
  const surroundingContainers = [
    container.previousElementSibling,
    container,
    container.nextElementSibling,
  ].filter(isChunkContainer);
  // forceContentLoadForChunk is a no-op if chunk contents are already loaded, in which case this will be instant.
  await Promise.all(surroundingContainers.map(
    (container) => forceContentLoadForChunk(container.dataset.chunkId),
  ));
}
