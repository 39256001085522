import { useEffect, useState } from 'react';

import { DeferredPromise } from '../utils/DeferredPromise';
import { portalGate as portalGateToContentFrame } from './portalGates/contentFrame/to/reactNativeWebview';

export async function waitUntilDocumentContentAtPositionLoaded(serializedPosition: string): Promise<void> {
  const isContentChunked = await portalGateToContentFrame.methods.contentIsChunked();
  if (!isContentChunked) {
    return;
  }

  const eventToken = Math.random().toString().slice(2);
  const promise = new DeferredPromise<void>();
  const onChunkContentAtPositionLoad = (token: string) => {
    if (token !== eventToken) {
      return;
    }
    promise.resolve();
    portalGateToContentFrame.off('chunk-content-at-position-loaded', onChunkContentAtPositionLoad);
  };
  portalGateToContentFrame.on('chunk-content-at-position-loaded', onChunkContentAtPositionLoad);
  portalGateToContentFrame.methods.emitEventWhenChunkContentAtPositionLoads(serializedPosition, eventToken);
  return promise;
}


export function useHasLoadedDocumentContentAtPosition(serializedPosition: string | null | undefined): boolean {
  const [hasLoadedContent, setHasLoadedContent] = useState(false);

  useEffect(() => {
    const setHasLoadedContentTrue = () => {
      setHasLoadedContent(true);
    };
    if (!serializedPosition) {
      // if no serialized position, that means this is the first time doc is opened.
      // in this case, the first few chunks will be loaded when the doc initializes.
      portalGateToContentFrame.once('chunk-content-initialized', setHasLoadedContentTrue);
      portalGateToContentFrame.methods.emitEventWhenChunkedContentInitialized();
      return;
    }
    const eventToken = Math.random().toString().slice(2);
    const onChunkContentAtPositionLoad = (token: string) => {
      if (token !== eventToken) {
        return;
      }
      setHasLoadedContent(true);
      portalGateToContentFrame.off('chunk-content-at-position-loaded', onChunkContentAtPositionLoad);
    };
    portalGateToContentFrame.on('chunk-content-at-position-loaded', onChunkContentAtPositionLoad);
    portalGateToContentFrame.methods.emitEventWhenChunkContentAtPositionLoads(serializedPosition, eventToken);

    return () => {
      portalGateToContentFrame.off('chunk-content-at-position-loaded', onChunkContentAtPositionLoad);
      portalGateToContentFrame.off('chunk-content-initialized', setHasLoadedContentTrue);
    };
  }, [serializedPosition]);

  return hasLoadedContent;
}

