import { EventEmitter2, Listener } from 'eventemitter2';
import chunk from 'lodash/chunk';

import nowTimestamp from '../../utils/dates/nowTimestamp';
import delay from '../../utils/delay';
import { isMobile } from '../../utils/environment';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import makeLogger, { type ReadwiseLogger } from '../../utils/makeLogger';
// eslint-disable-next-line import/no-cycle
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import { KeysOfType, ToStringable } from '../../utils/typescriptUtils';
import { CacheInstance } from '../Cache';
import { Cache } from '../cache.platform';

type APIError = {
  code: string;
  id: string;
  meta?: {
    [name: string]: boolean | number | string;
  };
  title: string;
};

type APICursor = ToStringable;

interface APIResponseBody<TItem> {
  data: TItem[];
  errors?: APIError[];
  meta?: {
    page: {
      limit: number;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      next_cursor: APICursor;
    };
  };
}

type OngoingFullLoadAll = {
  nextCursor: APICursor;
  requestStartDatetime: number;
};

type StoreOptions = {
  couldServerReturnFewerItemsThanRequested?: boolean;
  endpointPrefix?: string;
  identifierProperty?: string;
  name: string;
};

/*
  It's not possible to use private fields / methods yet with react-native,
  so we use a `_` prefix instead. Please add/remove it when making something
  private/public.
 */
class Store<TItem, TIdentifierPropertyName extends KeysOfType<TItem, string>> extends EventEmitter2 {
  hasLoadedAll: boolean;
  hasLoadedAny: boolean;

  _cache: CacheInstance;
  _identifierProperty: TIdentifierPropertyName;
  _itemCacheKeyPrefix = 'item__';
  _logger: ReadwiseLogger;
  _ongoingLoadAllPromise: Promise<void> | null = null;
  _options: StoreOptions & { endpointPrefix: NonNullable<StoreOptions['endpointPrefix']>; };

  constructor(options: StoreOptions) {
    super();
    this.hasLoadedAll = false;
    this.hasLoadedAny = false;

    this._logger = makeLogger(`${options.name} store`);

    this._options = {
      ...options,
      endpointPrefix: options.endpointPrefix ?? options.name,
    };

    this._identifierProperty = (this._options.identifierProperty ||
      'id') as unknown as TIdentifierPropertyName;

    // Use a separate namespace / DB for this store's cache (this does not create a DB every time its called)
    this._cache = Cache.createInstance({
      name: `store__${this._options.name}`,
    });
  }

  async clearCache(): Promise<void> {
    this._logger.debug('Clearing cache...');
    await this._cache.clear();
    this._logger.debug('Cleared cache!');
  }

  async get(identifier: string): Promise<TItem | null> {
    return this._cache.getItem<TItem>(
      this._getItemCacheKey({
        [this._identifierProperty]: identifier,
      } as unknown as Pick<TItem, TIdentifierPropertyName>),
    );
  }

  async checkIfIdentifiersExist(identifiers: string[]): Promise<{ [identifier: string]: boolean; }> {
    const returnedItems = await Promise.all(identifiers.map((identifier) => this.get(identifier)));
    const existsMap = identifiers.reduce((acc, identifier, index) => {
      acc[identifier] = returnedItems[index] !== null;
      return acc;
    }, {} as { [identifier: string]: boolean; });

    return existsMap;
  }

  async loadByIds(identifiersArgument: string[], shouldBypassCache?: boolean) {
    this._logger.debug('loadByIds', { identifiersArgument, shouldBypassCache });
    const identifiers = identifiersArgument as unknown as TItem[TIdentifierPropertyName][];
    if (!identifiers.length) {
      throw new Error('No IDs given');
    }
    this._logger.debug('loadByIds', { identifiers });

    this.emit('items-load-requested', { identifiers });

    // Skip reloading cached items. Exit if all are cached
    let filteredIdentifiers = identifiers;

    if (!shouldBypassCache) {
      const cacheKeys = identifiers.map((id) =>
        this._getItemCacheKey({
          [this._identifierProperty]: id,
        } as Pick<TItem, TIdentifierPropertyName>));
      const itemsFromCache = (await this._cache.getItems(cacheKeys)) as { [key: string]: TItem; };

      const cacheHits = Object.values(itemsFromCache);

      filteredIdentifiers = cacheKeys
        .filter((id) => !(id in itemsFromCache))
        .map((id) => id.replace(this._itemCacheKeyPrefix, '')) as unknown as typeof filteredIdentifiers;
      if (cacheHits.length) {
        this.emit('items-received-by-id', { foundItems: cacheHits, missingIds: [] });
      }
      if (!filteredIdentifiers.length) {
        return;
      }
    }

    return this._performLoad({
      identifiers: filteredIdentifiers,
      requestStartDatetime: nowTimestamp(),
      updatedSince: undefined,
    });
  }

  async loadAll() {
    this._logger.debug('loadAll called');
    // Re-use the load-all promise as it can trigged after a timeout or based on user behaviour
    if (this._ongoingLoadAllPromise) {
      return this._ongoingLoadAllPromise;
    }
    this._logger.debug('loadAll actually starting');

    /*
      If we're loading all *again*:

      1. First we check if there was an ongoing full load all last time the app was
        running; i.e. the load all we do when the first app is opened for the first
        time / after the cache is cleared. If so, we finish that first (continuing
        from the next page cursor).
      2. Then / otherwise, we only load those that have changed since the last time
        (request start time).
    */
    let updatedSinceArgument: number | undefined;
    let cursorArgument: number | undefined;
    const ongoingFullLoadAll = await this._cache.getItem<OngoingFullLoadAll>('ongoingFullLoadAll');

    if (ongoingFullLoadAll) {
      await this._performLoad({
        cursorToStartAt: ongoingFullLoadAll.nextCursor,
        identifiers: [],
        requestStartDatetime: ongoingFullLoadAll.requestStartDatetime,
      });

      // No need to wait for this promise:
      this._cache.setItem('lastLoadAllDatetime', ongoingFullLoadAll.requestStartDatetime);

      updatedSinceArgument = ongoingFullLoadAll.requestStartDatetime;
    } else {
      // If there's no ongoing load all, get all updates since last time we synced:
      const lastLoadAllDatetime = await this._cache.getItem<number>('lastLoadAllDatetime');
      if (lastLoadAllDatetime) {
        updatedSinceArgument = lastLoadAllDatetime;

        const ongoingLoadUpdatesCursor = await this._cache.getItem<number>('ongoingLoadUpdatesCursor');
        if (ongoingLoadUpdatesCursor) {
          cursorArgument = ongoingLoadUpdatesCursor;
        }
      }
    }

    const requestStartDatetime = nowTimestamp();
    const loadPromise = this._performLoad({
      identifiers: [],
      requestStartDatetime,
      updatedSince: updatedSinceArgument,
      cursorToStartAt: cursorArgument,
    });

    // When loading all, we need to do some stuff once done
    this._ongoingLoadAllPromise = loadPromise
      .then(() => {
        this._ongoingLoadAllPromise = null;
        // No need to wait for this promise:
        this._cache.setItem('lastLoadAllDatetime', requestStartDatetime);
      })
      .catch((e) => {
        this._ongoingLoadAllPromise = null;
        throw e;
      });

    return loadPromise;
  }

  async getAll(): Promise<TItem[]> {
    const cacheKeys = await this._cache.keys();
    const itemCacheKeys = cacheKeys.filter((cacheKey) => cacheKey.startsWith(this._itemCacheKeyPrefix));

    const results: TItem[] = [];

    let i = 0;
    for (const itemCacheKeyGroup of chunk(itemCacheKeys, 100)) {
      i++;
      if (i > 1) {
        await delay(10);
      }
      const items = (await this._cache.getItems(itemCacheKeyGroup)) as { [key: string]: TItem; };
      results.push(...Object.values(items));
    }
    return results;
  }

  onItemsReceivedFromServer(callback: (items: TItem[]) => void): Listener {
    return this.on('items-received-from-server', callback) as Listener;
  }

  onItemsReceivedById(
    callback: (args: {
      foundItems: TItem[];
      missingIds: TItem[TIdentifierPropertyName][];
    }) => void,
  ): Listener {
    return this.on('items-received-by-id', callback) as Listener;
  }

  // Shorthand for logging an error message and instantiating an Error
  _createErrorAndLog(message: string, meta: { [key: string]: unknown; }): Error {
    this._logger.error(message, meta);
    return new Error(message);
  }

  async _fetchPage({
    cursor,
    identifiers = [],
    updatedSince,
  }: {
    cursor?: APICursor;
    identifiers: TItem[TIdentifierPropertyName][];
    updatedSince?: number;
  }): Promise<APIResponseBody<TItem>> {
    const queryParams = new URLSearchParams();
    if (identifiers.length) {
      queryParams.append(`filter[${String(this._identifierProperty)}]`, identifiers.join(','));
    }
    if (updatedSince) {
      queryParams.append('filter[updated_at][gt]', updatedSince.toString());
    }
    if (cursor) {
      queryParams.append('page[cursor]', cursor.toString());
    }

    this._logger.time(`Fetching page ${cursor?.toString()}`);
    const url = `${getServerBaseUrl()}/reader/api/${this._options.endpointPrefix.replace(
      /^\/|\/$/g,
      '',
    )}?${queryParams}`;
    const response = await requestWithAuth(url, {
      credentials: 'include',
      method: 'GET',
      mode: 'cors',
    });

    let body: APIResponseBody<TItem> | undefined;
    try {
      body = await response.json();
    } catch (e) {
      // Ignore now, this is handled later
    }
    this._logger.timeEnd(`Fetching page ${cursor?.toString()}`);

    if (!body) {
      throw this._createErrorAndLog('fetchPage: failed to parse response body', {
        cursor,
        identifiers,
        response,
        url,
      });
    }

    return body;
  }

  _getItemCacheKey(item: Pick<TItem, TIdentifierPropertyName>): string {
    return `${this._itemCacheKeyPrefix}${item[this._identifierProperty]}`;
  }

  async _performLoad({
    cursorToStartAt,
    identifiers,
    requestStartDatetime,
    updatedSince,
  }: {
    cursorToStartAt?: APICursor;
    identifiers: TItem[TIdentifierPropertyName][];
    requestStartDatetime: number;
    updatedSince?: number;
  }): Promise<void> {
    let nextCursor: APICursor | undefined = cursorToStartAt;
    let hasReceivedErrors = false;

    /*
      This is when the app is launched for the first time / after cache is cleared and
      we load all items (paginated)
    */
    const isFullyLoadingAll = !identifiers.length && !updatedSince;
    const isLoadingUpdates = !identifiers.length && Boolean(updatedSince);

    // Load one page, keep loading more if there's a next_cursor returned
    do {
      const pageResult = await this._fetchPage({ cursor: nextCursor, identifiers, updatedSince });

      if (pageResult.errors?.length) {
        this._logger.warn('load received some errors', { cursor: nextCursor, identifiers, pageResult });
        hasReceivedErrors = true;
      } else if (
        pageResult.data.length < identifiers.length &&
        !this._options.couldServerReturnFewerItemsThanRequested
      ) {
        this._logger.warn(`load: server returned fewer items than requested (and no errors)`, {
          identifiers,
          nextCursor,
          updatedSince,
        });
      }

      await this._upsert(identifiers, pageResult.data);

      this.hasLoadedAny = true;
      this._emitLoadedStatusChangedEvent();
      nextCursor = pageResult.meta?.page?.next_cursor;

      if (isFullyLoadingAll) {
        // Cache the next cursor in case the app is closed, so we can continue next time it's opened.
        if (nextCursor) {
          await this._cache.setItem<OngoingFullLoadAll>('ongoingFullLoadAll', {
            nextCursor,
            requestStartDatetime,
          });
        } else {
          await this._cache.removeItem('ongoingFullLoadAll');
        }
      } else if (isLoadingUpdates) {
        // When loading updates, we can still have many pages of updates.
        // Cache the cursor, so if the app is closed we can continue non-wastefully.
        if (nextCursor) {
          await this._cache.setItem('ongoingLoadUpdatesCursor', nextCursor);
        } else {
          await this._cache.removeItem('ongoingLoadUpdatesCursor');
        }
      }
    } while (nextCursor);

    // If we've successfully loaded all:
    if (!hasReceivedErrors && !identifiers.length) {
      this.hasLoadedAll = true;
      this._emitLoadedStatusChangedEvent();
    }
  }

  _emitLoadedStatusChangedEvent() {
    this.emit('loaded-state-changed', {
      hasLoadedAll: this.hasLoadedAll,
      hasLoadedAny: this.hasLoadedAny,
    });
  }

  async _upsert(identifiers: TItem[TIdentifierPropertyName][], items: TItem[]): Promise<void> {
    const callMarker = Math.random().toString().slice(2);
    this._logger.debug('_upsert', { callMarker });
    this._logger.time(`_upsert#${callMarker}`);

    for (const item of items) {
      await this._cache.setItem(this._getItemCacheKey(item), item); // don't wait for promise to resolve
      await delay(isMobile ? 5 : 1);
    }

    // NOTE: There is no code concerned with checking if an item already exists or anything like that
    //  Right now that needs to be handled in the foreground (e.g see fetchRelatedRSSUnthrottled)
    if (items.length) {
      this.emit('items-received-from-server', items);
    }

    if (identifiers.length) {
      const identifiersFound = new Set(items.map((item) => item[this._identifierProperty]));
      const missingIds = identifiers.filter((id) => !identifiersFound.has(id));
      this.emit('items-received-by-id', { foundItems: items, missingIds });
    }
    this._logger.timeEnd(`_upsert#${callMarker}`);
  }
}

export default Store;
