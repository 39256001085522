/**
 * Extracts all content between the <body></body> tags of the given HTML.
 */
export function extractHtmlBodyContent(html: string): string | null {
  const regex = /<body(?:\s+[^>]*?[^/>])?>([\s\S]*?)<\/body\s*>/i;
  const match = html.match(regex);

  if (match && match[1]) {
    return match[1];
  }

  return null;
}
