import { extractHtmlStringFromDomElement, RWDOMParser } from '../../utils/RWDOMParser.platform';

const SCOPE_SELECTOR = '.epub-original-styles';

// This function scopes the CSS to the .rw-chunk-container element to avoid
// CSS from leaking out of the chunked document and colliding with styles on our
// UI elements.
function scopeCSS(cssContent: string): string {
  const processedCSS = cssContent.replace(
    /@media[^{]+{([^{}]*{[^{}]*}[^{}]*)*}/g,
    (mediaQuery) => {
      const [condition, rules] = mediaQuery.match(/@media[^{]+{(.+)}/)?.slice(1) ?? [];
      if (!condition || !rules) {
        return mediaQuery;
      }

      const scopedRules = rules.replace(
        /([^{};]+){([^{}]*)}/g,
        (_match, selector, rules) => {
          if (selector.trim().startsWith('@')) {
            return `${_match}\n`;
          }
          const scopedSelector = selector
            .split(',')
            .map((s: string) => `${SCOPE_SELECTOR} ${s.trim()}`)
            .join(', ');
          return `${scopedSelector}{${rules}}\n`;
        },
      );

      return `@media${condition}{${scopedRules}}\n`;
    },
  );

  return processedCSS.replace(
    /([^{};]+){([^{}]*)}/g,
    (match, selector, rules) => {
      const trimmedSelector = selector.trim();
      if (trimmedSelector.startsWith('@')) {
        return `${match}\n`;
      }

      const scopedSelector = selector
        .split(',')
        .map((s: string) => `${SCOPE_SELECTOR} ${s.trim()}`)
        .join(', ');

      return `${scopedSelector}{${rules}}\n`;
    },
  );
}

export async function inlineStyles(
  htmlString: string,
  fetchStylesheet: (url: string) => Promise<string | null>,
): Promise<string> {
  const parser = new RWDOMParser() as DOMParser;
  const doc = parser.parseFromString(htmlString, 'text/html');

  const links = Array.from(doc.getElementsByTagName('link'));
  const stylesheetLinks = links.filter((link: Element) =>
    link.getAttribute('rel') === 'stylesheet');

  const cssContents = await Promise.all(
    stylesheetLinks.map(async (link: Element) => {
      const href = link.getAttribute('href');
      if (!href) {
        return null;
      }
      const css = await fetchStylesheet(href);
      return css ? scopeCSS(css) : null;
    }),
  );

  stylesheetLinks.forEach((link: Element) => {
    const parent = link.parentNode;
    if (parent) {
      parent.removeChild(link);
    }
  });

  const body = doc.getElementsByTagName('body')[0];
  if (body) {
    cssContents.reverse().forEach((cssContent) => {
      if (!cssContent) {
        return;
      }
      const style = doc.createElement('style');
      style.textContent = cssContent;
      body.insertBefore(style, body.firstChild);
    });
  }

  const documentElementString = extractHtmlStringFromDomElement(doc.documentElement);

  if (!documentElementString) {
    return htmlString;
  }

  return documentElementString;
}
