import { CompletionContext } from '@codemirror/autocomplete';
import { tags as t } from '@lezer/highlight';
import { langs } from '@uiw/codemirror-extensions-langs';
import { createTheme, CreateThemeOptions } from '@uiw/codemirror-themes';
import { type Prompt, PromptModel, PromptScopeType } from 'shared/foreground/ghostreader';

const promptModelLabels = {
  [PromptModel.GPT_35_TURBO]: 'GPT-3.5 Turbo',
  [PromptModel.GPT_4]: 'GPT-4',
  [PromptModel.GPT_4_TURBO]: 'GPT-4 Turbo',
  [PromptModel.GPT_4o]: 'GPT-4o',
  [PromptModel.GPT_4o_MINI]: 'GPT-4o mini',
  [PromptModel.O1]: 'o1',
  [PromptModel.O1_MINI]: 'o1-mini',
};

const completions = (context: CompletionContext) => {
  const word = context.matchBefore(/\w*/);
  if (!word || word.from === word.to && !context.explicit) {
    return null;
  }

  return {
    from: word.from,
    options: [
      // control structures
      { label: 'if', type: 'keyword', apply: '{% if -%}' },
      { label: 'elif', type: 'keyword', apply: '{% elif -%}' },
      { label: 'endif', type: 'keyword', apply: '{% endif -%}' },
      { label: 'for', type: 'keyword', apply: '{% for -%}' },
      { label: 'endfor', type: 'keyword', apply: '{% endfor -%}' },
      // variables
      { label: 'document', type: 'variable' },
      { label: 'document.author', type: 'variable' },
      { label: 'document.content', type: 'variable' },
      { label: 'document.domain', type: 'variable' },
      { label: 'document.html', type: 'variable' },
      { label: 'document.language', type: 'variable' },
      { label: 'document.note', type: 'variable' },
      { label: 'document.position', type: 'variable' },
      { label: 'document.summary', type: 'variable' },
      { label: 'document.tags', type: 'variable' },
      { label: 'document.title', type: 'variable' },

      { label: 'highlight', type: 'variable' },
      { label: 'highlight.note', type: 'variable' },
      { label: 'highlight.tags', type: 'variable' },

      { label: 'highlights', type: 'variable' },

      { label: 'selection', type: 'variable' },
      { label: 'selection.sentence', type: 'variable' },
      { label: 'selection.paragraph', type: 'variable' },
      // subroutines
      { label: 'central_paragraphs', type: 'function' },
      { label: 'central_sentences', type: 'function' },
      { label: 'count_tokens', type: 'function' },
      { label: 'document_range', type: 'function' },
      { label: 'key_sentences', type: 'function' },
      { label: 'lexical_search', type: 'function' },
      { label: 'most_similar', type: 'function' },
      { label: 'truncate', type: 'function' },
    ],
  };
};

export const jinja2 = langs.jinja2();
export const jinja2completions = jinja2.data.of({
  autocomplete: completions,
});

export const getSelectedLabel = (selectedOption: PromptModel) => promptModelLabels[selectedOption];

export const promptId = (scopeType: PromptScopeType, prompt: Prompt) => {
  return `prompt-${scopeType}-${prompt.type}-${prompt.position}`;
};

export const defaultSettingsBasicLight: CreateThemeOptions['settings'] = {
  background: 'var(--background-primary)',
  caret: '#3b4252',
  fontFamily: 'var(--font-family-monospace)',
  foreground: 'var(--text-primary)',
  gutterActiveForeground: 'var(--text-primary)',
  selection: 'var(--background-secondary)',
  selectionMatch: 'var(--background-secondary)',
  gutterBackground: '#eceff4',
  gutterForeground: '#2e3440',
  gutterBorder: 'none',
  lineHighlight: '#02255f11',
};

export const readwiseTheme = (options?: Partial<CreateThemeOptions>, isDarkMode?: boolean) => {
  const { theme = isDarkMode ? 'dark' : 'light', settings = {}, styles = [] } = options ?? {};
  return createTheme({
    theme,
    settings: {
      ...defaultSettingsBasicLight,
      ...settings,
    },
    styles: [
      { tag: t.keyword, color: 'var(--purple-40)', fontWeight: 'var(--font-weight-medium)' },
      {
        tag: [t.name, t.deleted, t.character, t.propertyName, t.macroName],
        color: 'var(--purple-40)',
        fontWeight: 'var(--font-weight-medium)',
      },
      { tag: [t.variableName], color: 'var(--purple-40)', fontWeight: 'var(--font-weight-medium)' },
      { tag: [t.function(t.variableName)], color: 'var(--purple-40)' },
      { tag: [t.labelName], color: 'var(--purple-40)' },
      {
        tag: [t.color, t.constant(t.name), t.standard(t.name)],
        color: 'var(--purple-40)',
      },
      { tag: [t.definition(t.name), t.separator], color: 'var(--purple-40)' },
      { tag: [t.brace], color: 'var(--purple-40)' },
      {
        tag: [t.annotation],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.number, t.changed, t.annotation, t.modifier, t.self, t.namespace],
        fontWeight: 'var(--font-weight-bold)',
        color: 'var(--purple-40)',
      },
      {
        tag: [t.typeName, t.className],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.operator, t.operatorKeyword],
        color: 'rgba(140,60,162,0.5)',
        fontWeight: 'var(--font-weight-bold)',
      },
      {
        tag: [t.tagName],
        fontWeight: 'var(--font-weight-bold)',
        color: 'rgba(140,60,162,0.5)',
      },
      {
        tag: [t.squareBracket],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.angleBracket],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.attributeName],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.regexp],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.quote],
        color: 'var(--purple-40)',
      },
      { tag: [t.string], color: 'var(--purple-40)' },
      {
        tag: t.link,
        color: 'var(--purple-40)',
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
      },
      {
        tag: [t.url, t.escape, t.special(t.string)],
        color: 'var(--purple-40)',
      },
      { tag: [t.meta], color: 'var(--purple-40)' },
      { tag: [t.comment], color: 'var(--purple-40)', fontStyle: 'italic' },
      { tag: t.strong, fontWeight: 'var(--font-weight-medium)', color: 'var(--purple-40)' },
      { tag: t.emphasis, fontStyle: 'italic', color: 'var(--purple-40)' },
      { tag: t.strikethrough, textDecoration: 'line-through' },
      { tag: t.heading, fontWeight: 'var(--font-weight-medium)', color: 'var(--purple-40)' },
      { tag: t.special(t.heading1), fontWeight: 'var(--font-weight-medium)', color: 'var(--purple-40)' },
      { tag: t.heading1, fontWeight: 'var(--font-weight-medium)', color: 'var(--purple-40)' },
      {
        tag: [t.heading2, t.heading3, t.heading4],
        fontWeight: 'var(--font-weight-medium)',
        color: 'var(--purple-40)',
      },
      {
        tag: [t.heading5, t.heading6],
        color: 'var(--purple-40)',
      },
      { tag: [t.atom, t.bool, t.special(t.variableName)], color: 'var(--purple-40)' },
      {
        tag: [t.processingInstruction, t.inserted],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.contentSeparator],
        color: 'var(--purple-40)',
      },
      { tag: t.invalid, color: 'var(--purple-40)', borderBottom: '1px dotted #d30102' },
      ...styles,
    ],
  });
};
