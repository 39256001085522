import React, { useEffect, useMemo, useState } from 'react';
import {
  startChunkContainerIntersectionObservers,
  stopChunkContainerIntersectionObservers,
} from 'shared/foreground/contentFramePortalGateInternalMethods';
import { useHasDocumentContentInitialized } from 'shared/foreground/useHasDocumentContentInitialized';
import getNextElementWithinContainer from 'shared/foreground/utils/getNextNodeWithinContainer';
import {
  findScrollTargetInChunkContainer,
  forceChunkContentLoadForContainer,
} from 'shared/foreground/utils/scrollToChunk';
import { EpubToc } from 'shared/types';
import { isChunkContainer } from 'shared/typeValidators';

import { useIsLeftSidebarHidden } from '../hooks/hooks';
import styles from './TableOfContents.module.css';

type HeadingElement = HTMLElement & {
  dataset: {
    rwEpubToc: string;
  };
};

const isHeading = (el: Element): el is HeadingElement => el.hasAttribute('data-rw-epub-toc');

// Takes an element and generates a table of contents. It will update the content if needed
export default function EpubTableOfContents({
  contentRoot,
  tocItems,
  currentFocusedElement = null,
}: {
  contentRoot: HTMLElement;
  tocItems: EpubToc[];
  currentFocusedElement: HTMLElement | null;
}): JSX.Element | null {
  const [activeId, setActiveId] = useState('');
  const leftSidebarHidden = useIsLeftSidebarHidden();
  const hasDocumentContentInitialized = useHasDocumentContentInitialized();

  const headings = useMemo(
    () => Array.from(contentRoot.querySelectorAll('[data-rw-epub-toc]')),
    // need to wait for chunked content to load before querying content root.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentRoot, hasDocumentContentInitialized],
  );
  useEffect(() => {
    if (!currentFocusedElement || !contentRoot || !headings.length) {
      return;
    }

    if (isHeading(currentFocusedElement)) {
      setActiveId(currentFocusedElement?.getAttribute('data-rw-epub-toc') || '');
      return;
    }

    const closestUpperHeading = getNextElementWithinContainer({
      container: contentRoot,
      direction: 'previous',
      element: currentFocusedElement,
      matcher: isHeading,
      checkParents: true,
    });

    const newClosestUpperHeadingId =
      closestUpperHeading?.getAttribute('data-rw-epub-toc') ??
      headings[0].getAttribute('data-rw-epub-toc');

    setActiveId(newClosestUpperHeadingId || '');
  }, [currentFocusedElement, contentRoot, activeId, headings]);

  const items = useMemo(() =>
    tocItems.map((tocItem: EpubToc): JSX.Element => {
      const itemId = tocItem.id;
      const name = tocItem.title;
      const isActive = activeId === itemId;

      const className = `${styles.listItemWrapper} ${isActive ? styles.active : ''} ${
        styles[`listItemLevel${tocItem.level}`]
      }`;
      const listItemClassName = leftSidebarHidden ? styles.hidden : '';
      const onClick = async () => {
        let headingElement = contentRoot.querySelector(
          `#document-reader-root [data-rw-epub-toc="${tocItem.id}"]`,
        );
        if (isChunkContainer(headingElement)) {
          await stopChunkContainerIntersectionObservers();
          await forceChunkContentLoadForContainer(headingElement);
          headingElement = findScrollTargetInChunkContainer(headingElement);
        }
        headingElement?.scrollIntoView();
        if (isChunkContainer(headingElement)) {
          await startChunkContainerIntersectionObservers();
        }
        setActiveId(itemId);
      };
      return (
        <button type="button" onClick={onClick} className={className} key={itemId}>
          <div className={styles.bar} />
          <li className={listItemClassName}>{name}</li>
        </button>
      );
    }), [activeId, contentRoot, leftSidebarHidden, tocItems]);

  if (!headings.length) {
    return null;
  }

  return (
    <div className={`${styles.root}`}>
      <h4 id="toc-title" className={`${styles.title} ${leftSidebarHidden ? styles.hidden : ''}`}>
        Contents
      </h4>
      <ol>{items}</ol>
    </div>
  );
}
