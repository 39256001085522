import isObject from 'lodash/isObject';

import type { ChunkContainerElement } from './foreground/types/chunkedDocuments';
import {
  AnyDocument,
  Article,
  Category,
  DocumentThatCanBeShared,
  DocumentThatCanHaveHighlights,
  DocumentWithAuthor,
  DocumentWithEmptyStateInstructionsSidebar,
  DocumentWithLanguage,
  DocumentWithOptionalTransientData,
  DocumentWithParent,
  DocumentWithParsedDocId,
  DocumentWithPublishedDate,
  DocumentWithSummary,
  DocumentWithThirdPartyUrl,
  DocumentWithTitle,
  DocumentWithUrl,
  DocumentWithWordCount,
  FileDocument,
  FirstClassDocument,
  Highlight,
  KeyOfDocumentWithTransientData,
  PartialDocument,
} from './types';

// See: https://stackoverflow.com/a/46700791
export const notEmpty = <TValue>(value: TValue | null | undefined | void): value is TValue => {
  if (value === null || value === undefined) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const testDummy: TValue = value;
  return true;
};

export const isDocument = (input: unknown): input is AnyDocument => {
  if (input && isObject(input)) {
    const potentialDocument = input as { category?: unknown; id?: unknown; };
    return Boolean(potentialDocument.category && potentialDocument.id);
  }
  return false;
};

export function isDocumentWithParent<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is DocumentWithParent | PartialDocument<DocumentWithParent, 'category' | Keys> {
  return [Category.Highlight, Category.Note].includes(doc.category);
}

export function isFileDocument<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is FileDocument | PartialDocument<FileDocument, 'category' | Keys> {
  return [Category.PDF].includes(doc.category);
}

export function isDocumentWithAuthor<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is DocumentWithAuthor | PartialDocument<DocumentWithAuthor, 'category' | Keys> {
  return [
    Category.Article,
    Category.Email,
    Category.PDF,
    Category.RSS,
    Category.EPUB,
    Category.Tweet,
    Category.Video,
  ].includes(doc.category);
}

export function isDocumentWithLanguage<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is DocumentWithLanguage | PartialDocument<DocumentWithLanguage, 'category' | Keys> {
  return [
    Category.Article,
    Category.Email,
    Category.PDF,
    Category.RSS,
    Category.EPUB,
    Category.Tweet,
    Category.Video,
  ].includes(doc.category);
}
export function isDocumentWithParsedDocId<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is DocumentWithParsedDocId | PartialDocument<DocumentWithParsedDocId, 'category' | Keys> {
  return [
    Category.Article,
    Category.Email,
    Category.PDF,
    Category.RSS,
    Category.EPUB,
    Category.Tweet,
    Category.Video,
  ].includes(doc.category);
}

export function isFirstClassDocument<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is
  | DocumentWithOptionalTransientData<FirstClassDocument>
  | PartialDocument<DocumentWithOptionalTransientData<FirstClassDocument>, 'category' | Keys> {
  return [
    Category.Article,
    Category.Email,
    Category.PDF,
    Category.RSS,
    Category.EPUB,
    Category.Tweet,
    Category.Video,
  ].includes(doc.category);
}

export function isHighlight<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is Highlight | PartialDocument<Highlight, 'category' | Keys> {
  return [Category.Highlight].includes(doc.category);
}

export function isArticle<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is Article | PartialDocument<Article, 'category' | Keys> {
  return [Category.Article].includes(doc.category);
}

export function isDocumentWithTitle<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is DocumentWithTitle | PartialDocument<DocumentWithTitle, 'category' | Keys> {
  return [
    Category.Article,
    Category.Email,
    Category.PDF,
    Category.RSS,
    Category.EPUB,
    Category.Tweet,
    Category.Video,
  ].includes(doc.category);
}

export const isDocumentThatCanBeShared = <Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys> | null | void,
): doc is DocumentThatCanBeShared | PartialDocument<DocumentThatCanBeShared, 'category' | Keys> => {
  return Boolean(
    doc &&
      [
        Category.Article,
        Category.Email,
        Category.RSS,
        Category.Tweet,
        Category.Video,
        Category.PDF,
      ].includes(doc.category),
  );
};

export function isDocumentWithTTS<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | 'url' | Keys> | undefined | null,
): boolean {
  return (
    notEmpty(doc) &&
    isFirstClassDocument(doc) &&
    doc.category !== Category.Video &&
    !isYouTubeUrl(doc.url || '') &&
    (!isFileDocument(doc) || doc.category === Category.PDF)
  );
}

export function isDocumentThatCanHaveHighlights<
  Keys extends KeyOfDocumentWithTransientData<AnyDocument>,
>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is
  | DocumentThatCanHaveHighlights
  | PartialDocument<DocumentThatCanHaveHighlights, 'category' | Keys> {
  return [
    Category.Article,
    Category.Email,
    Category.PDF,
    Category.RSS,
    Category.EPUB,
    Category.Tweet,
    Category.Video,
  ].includes(doc.category);
}

export function isDocumentWithUrl<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is DocumentWithUrl | PartialDocument<DocumentWithUrl, 'category' | Keys> {
  return [
    Category.Article,
    Category.Email,
    Category.PDF,
    Category.RSS,
    Category.Tweet,
    Category.Video,
  ].includes(doc.category);
}

export function isDocumentWithThirdPartyUrl<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is DocumentWithThirdPartyUrl | PartialDocument<DocumentWithThirdPartyUrl, 'category' | Keys> {
  return [Category.Article, Category.PDF, Category.RSS, Category.Tweet, Category.Video].includes(
    doc.category,
  );
}

export function isDocumentWithWordCount<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is DocumentWithWordCount | PartialDocument<DocumentWithWordCount, 'category' | Keys> {
  return [
    Category.Article,
    Category.Email,
    Category.PDF,
    Category.RSS,
    Category.EPUB,
    Category.Tweet,
  ].includes(doc.category);
}

export function isDocumentWithPublishedDate<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is DocumentWithPublishedDate | PartialDocument<DocumentWithPublishedDate, 'category' | Keys> {
  return [
    Category.Article,
    Category.Email,
    Category.PDF,
    Category.RSS,
    Category.EPUB,
    Category.Tweet,
    Category.Video,
  ].includes(doc.category);
}

export function isDocumentWithSummary<Keys extends KeyOfDocumentWithTransientData<AnyDocument>>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'category' | Keys>,
): doc is DocumentWithSummary | PartialDocument<DocumentWithSummary, 'category' | Keys> {
  return [
    Category.Article,
    Category.Email,
    Category.RSS,
    Category.PDF,
    Category.EPUB,
    Category.Tweet,
    Category.Video,
  ].includes(doc.category);
}

export function isDocumentWithEmptyStateInstructionsSidebar(
  doc: AnyDocument,
): doc is DocumentWithEmptyStateInstructionsSidebar {
  return [
    Category.Article,
    Category.Email,
    Category.RSS,
    Category.PDF,
    Category.EPUB,
    Category.Tweet,
    Category.Video,
  ].includes(doc.category);
}

export function isDocumentWithPagination(doc: PartialDocument<AnyDocument, 'category' | 'url'>) {
  if (doc.url && isYouTubeUrl(doc.url)) {
    return false;
  }
  return doc.category !== Category.Video;
}

export function isChunkedDocument(
  doc: PartialDocument<AnyDocument, 'source_specific_data'> | null,
): boolean {
  return Boolean(doc?.source_specific_data?.epub?.is_chunked);
}

export function isHTMLElement(node?: Node | null): node is HTMLElement {
  return node?.nodeType === Node.ELEMENT_NODE;
}

export function isTextNode(node?: Node | null): node is Text {
  return node?.nodeType === Node.TEXT_NODE;
}

export function isYouTubeUrl(sourceUrl: string) {
  return sourceUrl.includes('youtube.com') || sourceUrl.includes('youtu.be');
}

export function isMediaSessionAction(input: string): input is MediaSessionAction {
  return [
    'nexttrack',
    'pause',
    'play',
    'previoustrack',
    'seekbackward',
    'seekforward',
    'seekto',
    'skipad',
    'stop',
  ].includes(input);
}

export function isChunkedDocumentContentRoot(contentRoot: Node): contentRoot is Element {
  return isHTMLElement(contentRoot) && contentRoot.classList.contains('rw-chunk-containers-root');
}

export function isContentRootWithMultipleChunks(contentRoot: Node): contentRoot is Element {
  return isHTMLElement(contentRoot) && contentRoot.querySelectorAll('.rw-chunk-container').length > 1;
}

export function isChunkContainer(node: Node | null): node is ChunkContainerElement {
  return isHTMLElement(node) && node.classList.contains('rw-chunk-container');
}
