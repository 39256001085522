import debounce from 'lodash/debounce';

// eslint-disable-next-line import/no-cycle
import {
  forceChunkContentLoadAtPosition,
  forceContentLoadForChunk,
  getPositionForTtsFromCurrentScrollPosition,
  getRectsFromTtsPosition,
  scrollToTtsPosition,
} from '../../foreground/contentFramePortalGateInternalMethods';
import {
  populateTtsAbleElements,
  TextToSpeechContentFrameError,
} from '../../foreground/contentFramePortalGateInternalMethods/textToSpeechUtils';
import type { ChunkContainerElement } from '../../foreground/types/chunkedDocuments';
import { populateFocusableElements } from '../../foreground/utils/findAllFocusableElements';
import {
  getVisibleElementsWithinRectBounds,
  isIntersecting,
} from '../../foreground/utils/findCenteredElementInViewport';
import { findChunkContainerForElement } from '../../foreground/utils/findChunkContainerForElement';
import getClosestHTMLElement from '../../foreground/utils/getClosestHTMLElement';
import getNextElementWithinContainer from '../../foreground/utils/getNextNodeWithinContainer';
import getRangyClassApplier from '../../foreground/utils/getRangyClassApplier';
import isFocusableElement from '../../foreground/utils/isFocusableElement';
import { isNodeAnHTMLElement } from '../../foreground/utils/isNodeAnHTMLElement';
import {
  deserializeCanonicalPosition,
  serializePositionAsCanonical,
} from '../../foreground/utils/locationSerialization/chunked';
import type { LenientReadingPosition, ReadingPosition, TtsPosition, WordBoundary } from '../../types';
import { isChunkContainer, isTextNode } from '../../typeValidators';
import makeLogger from '../../utils/makeLogger';
import { ScrollingManagerError } from './errors';
import type { MobileContentFrameWindow } from './types';

declare let window: MobileContentFrameWindow;

const IGNORED_DOCUMENT_CHILD_NODE_TAGS = new Set(['STYLE']);
function preventDefault(e: TouchEvent) {
  e.preventDefault();
}

export type ScrollListenerFunction = () => void;

export type SerializedPositionInfo = {
  serializedPosition: string;
  serializedPositionElementOffset: number;
};

export type PageRect = {
  // The top coordinate of the page (relative to entire document)
  top: number;
  // The bottom coordinate of the page (relative to entire document)
  bottom: number;
  // The height of the divider that sits above the page
  bottomPageDividerHeight: number;
};

type ScrollingManagerEventListener = {
  target: HTMLElement | Document | MobileContentFrameWindow;
  eventName: string;
  callback: (args: unknown) => void;
};

const logger = makeLogger(__filename, { shouldLog: false, isInsideWebview: true });
export class ScrollingManager {
  // This is a base class for all things scrolling
  document = document;
  headerComponent: HTMLElement | undefined;
  headerContainer: HTMLElement | undefined;
  documentTextContent: HTMLElement | undefined;
  documentRoot: HTMLElement | undefined;
  documentRootContainer: HTMLElement | undefined;
  headerContent: HTMLElement | undefined;
  headerImageContainer: HTMLElement | undefined;
  isOnResizeDisabled = false;
  ttsPosIndicator: HTMLElement | undefined;
  ttsPosIndicatorEnd: HTMLElement | undefined;
  ttsAutoScrollingEnabled = false;
  ttsAbleElements: HTMLElement[] = [];
  highlightableElements: Element[] = [];
  readingPosition: LenientReadingPosition | null = null;
  wordBoundaries: WordBoundary[] = [];
  lastTTSWord: string | undefined;
  isScrollingDown = false;
  currentScrollValue = 0;
  previousScrollValue = 0;
  scrollTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  scrollingEnabled = true;
  scrollEventsDisabledTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  touchMoveThrottle = 0;
  scrollingEventsDisabled = true;
  updatingCenterElementDisabled = false;
  documentTextContentHeight = 0;
  pageHeight = 800;

  hapticsOnScrollEnabled = false;
  smoothAnimationsDisabled = false;

  scrollListeners: ScrollListenerFunction[] = [];

  eventListeners: ScrollingManagerEventListener[] = [];

  initialized = false;
  currentCenteredElementInfo: { element?: HTMLElement | undefined | null; scrollDelta?: number; } = {};
  bodyObserver: ResizeObserver | undefined;

  baseLineHeight = 28;
  window: MobileContentFrameWindow = window;

  leftClickAreaWidth: number;
  rightClickAreaWidth: number;

  _debouncedStartUpdatingCenterElement = debounce(() => {
    this.updatingCenterElementDisabled = false;
  }, 1000);

  _internalChildrenForCloning: Range[] | undefined = undefined;

  _internalDocumentTextRanges: Range[] | undefined = undefined;

  constructor(window: MobileContentFrameWindow) {
    this.window = window;
    const deviceWidth = this.window.innerWidth;
    this.leftClickAreaWidth = Math.min(deviceWidth * 0.2, 120);
    this.rightClickAreaWidth = Math.max(deviceWidth * 0.8, deviceWidth - 120);
  }

  initializeCallback() {
    throw new ScrollingManagerError('On initialize callback was never created');
  }

  addEventListener(
    target: HTMLElement | Document | MobileContentFrameWindow,
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (args: any) => void,
  ) {
    target.addEventListener(eventName, callback);
    this.eventListeners.push({ target, eventName, callback });
  }

  removeAllEventListeners() {
    for (const eventObject of this.eventListeners) {
      const { target, eventName, callback } = eventObject;
      target.removeEventListener(eventName, callback);
    }
  }

  getScrollingElement() {
    if (this.document.scrollingElement === null) {
      throw new ScrollingManagerError('ScrollingElement is null!');
    }
    return this.document.scrollingElement as HTMLElement;
  }

  getScrollingElementTop() {
    return this.getScrollingElement().scrollTop;
  }

  getScrollingElementMaxScroll() {
    const { scrollHeight } = this.getScrollingElement();
    return scrollHeight;
  }

  getAbsoluteScrollTopOfElement(element: HTMLElement | Element | Range) {
    return this.getScrollingElementTop() + element.getBoundingClientRect().top;
  }

  getReadingPositionScrollTop() {
    let readingPositionScrollTop = 0;
    let readingPositionElement;
    if (this.readingPosition?.serializedPosition) {
      readingPositionElement = this.getElementFromSerializedPosition(
        this.readingPosition?.serializedPosition,
      );
      if (!readingPositionElement) {
        return this.getScrollingElement().scrollHeight * (this.readingPosition?.scrollDepth ?? 0);
      }
      readingPositionScrollTop = this.getAbsoluteScrollTopOfElement(readingPositionElement);
    }
    return readingPositionScrollTop;
  }

  setScrollingElementTop(newTop: number) {
    this.getScrollingElement().scrollTop = newTop;
  }

  scrollingElementScrollTo({ top, behavior }: { top: number; behavior: 'smooth' | 'auto' | 'instant'; }) {
    this.getScrollingElement().scrollTo({ top, behavior });
  }

  init() {
    this.disableScrollEvents();
    if (this.initialized) {
      throw new ScrollingManagerError(
        'ScrollingManager already initialized; make sure to not call init twice!',
      );
    }
    // Register functions for window so React Native can use them
    this.initializeHTMLComponents();
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    const newHeight = this.documentTextContent?.getBoundingClientRect().height;
    if (newHeight) {
      this.documentTextContentHeight = newHeight;
    }
    this.createResizeObserver();
    setTimeout(() => {
      this.enableScrollEvents();
    }, 200);
  }

  createResizeObserver() {
    // this set timeout accounts for a brief moment where fonts load but don't apply correctly,
    // resulting in an unnecessary resize event
    this.initializeHTMLComponents();
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    if (this.bodyObserver) {
      return;
    }
    this.bodyObserver = new ResizeObserver(() => this.onResize(false));
    this.bodyObserver.observe(this.documentTextContent);
  }

  destroy() {
    this.destroyResizeObserver();
  }

  destroyResizeObserver() {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    if (!this.bodyObserver) {
      return;
    }
    this.bodyObserver.unobserve(this.documentTextContent);
    delete this.bodyObserver;
  }

  onResize(force = false) {
    // This event is handled inside ResizeObserver which handles errors a bit annoyingly
    // eslint-disable-next-line no-alert
    alert(`HandleResize must be implemented in child class, ${force}`);
  }

  addScrollListener(func: ScrollListenerFunction) {
    this.scrollListeners.push(func);
  }

  disableScrollEventsForNMilliseconds(milliseconds = 1000) {
    this.scrollingEventsDisabled = true;
    if (this.scrollEventsDisabledTimer) {
      clearTimeout(this.scrollEventsDisabledTimer);
      this.scrollEventsDisabledTimer = undefined;
    }
    if (milliseconds > 0) {
      this.scrollEventsDisabledTimer = setTimeout(() => {
        this.scrollingEventsDisabled = false;
      }, milliseconds);
    } else {
      this.scrollingEventsDisabled = false;
    }
  }

  enableAllPaginationElements() {
    throw new ScrollingManagerError('enableAllPaginationElements must be implemented in child class');
  }

  disableAllPaginationElements() {
    throw new ScrollingManagerError('disableAllPaginationElements must be implemented in child class');
  }

  updateCurrentCenteredElement() {
    throw new ScrollingManagerError('UpdateCurrentCenteredElement must be implemented in child class');
  }

  disableScrollEvents() {
    this.scrollingEventsDisabled = true;
  }

  enableScrollEvents() {
    if (this.scrollEventsDisabledTimer) {
      clearTimeout(this.scrollEventsDisabledTimer);
      this.scrollEventsDisabledTimer = undefined;
    }
    this.scrollingEventsDisabled = false;
  }

  scrollToElement(element: HTMLElement, offset = 0, behavior: 'auto' | 'smooth' | 'instant' = 'smooth') {
    throw new ScrollingManagerError('ScrollToElement must be implemented in child class');
  }

  scrollToRect(rect: DOMRect, offset = 0) {
    throw new ScrollingManagerError('ScrollToRect must be implemented in child class');
  }

  scrollToPercentOfViewport(percent: number, animated = false, disableEvents = false) {
    throw new ScrollingManagerError('ScrollToPercentOfViewport must be implemented in child class');
  }

  async scrollToReadingPosition(readingPosition: LenientReadingPosition) {
    if (readingPosition.serializedPosition) {
      await forceChunkContentLoadAtPosition(readingPosition.serializedPosition);
      try {
        this.scrollToSerializedPosition(
          readingPosition.serializedPosition,
          readingPosition.mobileSerializedPositionElementVerticalOffset ?? 0,
        );
      } catch (e) {
        if (readingPosition.scrollDepth !== null) {
          this.scrollToPercentOfViewport(readingPosition.scrollDepth);
        }
      }
    } else if (readingPosition.scrollDepth !== null) {
      this.scrollToPercentOfViewport(readingPosition.scrollDepth);
    }
    this.updateCurrentCenteredElement();
  }

  scrollToSerializedPosition(serializedPosition: string, offset: number) {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError(
        'ScrollToSerializedPosition no document text content container found',
      );
    }

    const target = this.getElementFromSerializedPosition(serializedPosition);

    if (!target) {
      throw new ScrollingManagerError(
        `ScrollToSerializedPosition no target found for serialized position ${serializedPosition}`,
      );
    }


    this.currentCenteredElementInfo = { element: target, scrollDelta: offset };
    this.scrollToElement(target, -offset, 'auto');
  }

  initializeHTMLComponents() {
    if (!this.headerContent) {
      const headerContentResult = this.document.querySelector<HTMLElement>('.header-content');
      if (!headerContentResult) {
        throw new ScrollingManagerError('No .header-content found');
      }
      this.headerContent = headerContentResult;
    }
    const headerImageContainerResult = this.document.getElementById('header-image-container');
    if (!headerImageContainerResult) {
      throw new ScrollingManagerError('No #header-image-container found');
    }
    this.headerImageContainer = headerImageContainerResult;
    if (!this.headerComponent) {
      const headerComponentResult = this.document.getElementById('document-header');
      if (!headerComponentResult) {
        throw new ScrollingManagerError('No #header found');
      }
      this.headerComponent = headerComponentResult;
    }
    if (!this.headerContainer) {
      const headerContainerResult = this.document.querySelector<HTMLElement>('.header-container');
      if (!headerContainerResult) {
        throw new ScrollingManagerError('No .header-container found');
      }
      this.headerContainer = headerContainerResult;
    }
    if (!this.documentTextContent) {
      const documentContentResult = this.document.getElementById('document-text-content');
      if (!documentContentResult) {
        throw new ScrollingManagerError('No #document-text-content found');
      }
      this.documentTextContent = documentContentResult;
    }
    if (!this.documentRoot) {
      const documentRootResult = this.document.querySelector<HTMLElement>('.document-root');
      if (!documentRootResult) {
        throw new ScrollingManagerError('No .document-root found');
      }
      this.documentRoot = documentRootResult;
    }
    if (!this.documentRootContainer) {
      const documentRootContainerResult =
        this.document.querySelector<HTMLElement>('.document-container');
      if (!documentRootContainerResult) {
        throw new ScrollingManagerError('No .document-container found');
      }
      this.documentRootContainer = documentRootContainerResult;
    }
    const ttsPosIndicatorResult = document.querySelector<HTMLElement>('.tts-position-indicator-start');
    if (!ttsPosIndicatorResult) {
      throw new ScrollingManagerError('No .tts-position-indicator-start found');
    }
    this.ttsPosIndicator = ttsPosIndicatorResult;
    const ttsPosIndicatorEndResult = document.querySelector<HTMLElement>('.tts-position-indicator-end');
    if (!ttsPosIndicatorEndResult) {
      throw new ScrollingManagerError('No .tts-position-indicator-end found');
    }
    this.ttsPosIndicatorEnd = ttsPosIndicatorEndResult;
  }

  handleScrollFromHref() {}

  refreshPageSnapshotsForCurrentPage() {
    // Only implemented in paginated scrolling manager
  }

  setReadingPosition(pos: LenientReadingPosition | null) {
    this.readingPosition = pos;
  }

  updateWordBoundaries(wordBoundaries: WordBoundary[]) {
    this.wordBoundaries = wordBoundaries;
  }

  toggleTTSAutoScrolling(enabled: boolean) {
    this.ttsAutoScrollingEnabled = enabled;
  }

  isDocumentScrolledToBeginning(): boolean {
    throw new ScrollingManagerError('IsDocumentScrolledToBeginning must be implemented in child class');
  }

  getContentHeight(): number | undefined {
    throw new ScrollingManagerError('GetContentHeight must be implemented in child class');
  }

  getElementFromSerializedPosition(serializedPosition: string): HTMLElement | undefined {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError(
        'ScrollToSerializedPosition no document text content container found',
      );
    }

    try {
      const position = deserializeCanonicalPosition({
        classApplier: getRangyClassApplier(),
        rootNode: this.documentTextContent,
        serialized: serializedPosition,
      });

      const range = this.document.createRange();
      range.setStart(position.node, position.offset);
      range.setEnd(position.node, position.offset);
      const closestElement = getClosestHTMLElement(position.node);
      if (!closestElement) {
        throw new ScrollingManagerError('Could not get closest element from node');
      }

      return isFocusableElement(closestElement)
        ? closestElement
        : (getNextElementWithinContainer({
          container: this.documentTextContent,
          direction: 'next',
          element: closestElement,
          matcher: isFocusableElement,
        }) as HTMLElement);
    } catch (e) {
      logger.error('Error getting element from serialized position', { e });
    }
  }

  async playTtsFromCurrentScrollPosition() {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('Document element not found');
    }
    this.ttsAutoScrollingEnabled = false;

    const positionResult = await getPositionForTtsFromCurrentScrollPosition({
      contentContainer: this.documentTextContent,
      getIsDocumentScrolledToTop: () => this.isDocumentScrolledToBeginning(),
      ttsAbleElements: this.ttsAbleElements,
      window: this.window,
    });
    if (!positionResult) {
      return;
    }

    this.ttsAutoScrollingEnabled = true;
    const { elementIndex, position } = positionResult;

    if (typeof elementIndex !== 'undefined') {
      this.window.portalGateToForeground.emit('play-tts-from-element', { elementIndex, position });
      return;
    }
    this.window.portalGateToForeground.emit('play-tts-from-timestamp', { timestamp: position });
  }

  scrollViewportToCurrentTTSLocation(rect: DOMRect) {
    throw new ScrollingManagerError(
      'ScrollViewportToCurrentTTSLocation must be implemented in child class',
    );
  }

  getScrollDepthForTtsPosition(ttsPosition: TtsPosition): number | undefined {
    const scrollableRoot = this.getScrollingElement();
    const contentContainer = this.documentTextContent;
    if (!contentContainer || !scrollableRoot) {
      return;
    }
    const rect = getRectsFromTtsPosition({
      contentContainer,
      scrollableRoot,
      ttsPosition,
      returnRepeatedValue: true,
    })?.rect;
    if (!rect) {
      return;
    }
    return (rect.top + this.getScrollingElementTop() + 350) / (scrollableRoot.scrollHeight - scrollableRoot.clientHeight);
  }

  async scrollToTtsPosition(ttsPosition: TtsPosition, skipIndicatorUpdate: boolean) {
    const scrollableRoot = this.getScrollingElement();
    const contentContainer = this.documentTextContent;
    if (!contentContainer || !scrollableRoot) {
      return false;
    }

    // If TTS is not on, neither is auto-scrolling.
    // If that's the case, we want to briefly re-enable it to get to the position.
    const isScrollingManagerAutoScrollEnabled = this.ttsAutoScrollingEnabled;
    this.ttsAutoScrollingEnabled = true;
    await scrollToTtsPosition({
      contentContainer,
      scrollableRoot,
      ttsPosition,
      isAutoScrollEnabled: true,
      skipIndicatorUpdate,
      useRepeatedLastWord: true,
    });
    this.ttsAutoScrollingEnabled = isScrollingManagerAutoScrollEnabled;

    return true;
  }

  computeSerializedPositionFromCenteredElement(): SerializedPositionInfo | undefined {
    const { element, scrollDelta } = this.currentCenteredElementInfo;
    if (
      !this.documentTextContent ||
      !element ||
      scrollDelta === undefined ||
      !findChunkContainerForElement(element, this.documentTextContent)) {
      return;
    }
    const serializedPosition = serializePositionAsCanonical({
      classApplier: getRangyClassApplier(),
      node: element,
      offset: 0,
      rootNode: this.documentTextContent,
    });

    return {
      serializedPosition,
      serializedPositionElementOffset: scrollDelta,
    };
  }

  get currentScrollPosition(): ReadingPosition {
    const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();

    const currentScrollValue = this.getScrollingElementTop();
    const maxScrollValue = this.getScrollingElementMaxScroll();
    const { clientHeight } = this.getScrollingElement();

    return {
      serializedPosition: serializedPositionInfo?.serializedPosition ?? null,
      scrollDepth: Math.min(1, currentScrollValue / (maxScrollValue - clientHeight)),
      mobileSerializedPositionElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset ?? 0,
    };
  }

  createHighlightAtTtsPosition(ttsPos: TtsPosition) {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('Document element not found');
    }
    const { paraIndex } = ttsPos;

    if (!this.ttsAbleElements.length) {
      populateTtsAbleElements(this.documentTextContent, this.ttsAbleElements);
    }
    const node = this.ttsAbleElements[paraIndex];

    if (!node.textContent) {
      throw new TextToSpeechContentFrameError('Could not find text node');
    }

    const highlightRange = new Range();
    highlightRange.selectNode(node);

    const selection = this.document.getSelection();
    if (!selection) {
      throw new TextToSpeechContentFrameError('no selection');
    }
    selection.removeAllRanges();
    selection.addRange(highlightRange);

    this.window.portalGateToForeground.emit('create-highlight');
  }

  scrollToTop() {
    this.scrollToPercentOfViewport(0, true);
  }

  returnToReadingPosition() {
    throw new ScrollingManagerError('ReturnToReadingPosition must be implemented in child class');
  }

  onScrollStart() {
    throw new ScrollingManagerError('OnScrollStart must be implemented in child class');
  }

  // This is unthrottled, only add code to this if you need to listen to scroll a lot (like animating elements due to scroll position)
  onScroll() {
    throw new ScrollingManagerError('OnScroll must be implemented in child class');
  }

  onScrollEnd() {
    throw new ScrollingManagerError('OnScrollEnd must be implemented in child class');
  }

  onTouchMove(e: TouchEvent) {
    throw new ScrollingManagerError('OnTouchMove must be implemented in child class');
  }

  onContentChanged(forceReposition = false) {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('onContentChanged Document Text Container not found');
    }
    this.ttsAbleElements = [];
    this.highlightableElements = [];
    this._internalChildrenForCloning = undefined;
    this._internalDocumentTextRanges = undefined;
    populateFocusableElements(this.documentTextContent, this.highlightableElements);
    populateTtsAbleElements(this.documentTextContent, this.ttsAbleElements);
    if (forceReposition) {
      this.onResize(true);
    }
    logger.debug('onContentChanged', { highlightableElements: this.highlightableElements });
  }

  toggleScrollingEnabled(enabled: boolean) {
    if (!enabled) {
      if (this.scrollingEnabled) {
        this.document.body.style.overflow = 'hidden';
        this.window.addEventListener('touchmove', preventDefault, false); // mobile
      }
      this.scrollingEnabled = false;
    } else {
      this.window.removeEventListener('touchmove', preventDefault, false);
      this.document.body.style.overflow = 'visible';
      this.scrollingEnabled = true;
    }
  }

  // Util function to help move a debug border to a coordinate, useful for visualizing coordinates
  drawDebugBorderAtCoordY(coordY: number) {
    const border = this.document.querySelector<HTMLElement>('.debug-border');
    if (!border) {
      return;
    }
    border.style.top = `${coordY}px`;
    border.style.display = 'block';
  }

  isElementIntersectingAtYCoord(element: Element, yCoord: number) {
    if (!this.documentRoot) {
      throw new ScrollingManagerError('No document root found');
    }
    const relativeYCoord = this.documentRoot.getBoundingClientRect().top + yCoord;
    return isIntersecting(
      {
        top: relativeYCoord,
        bottom: relativeYCoord + 1,
        left: 0,
        right: this.window.innerWidth,
      },
      element,
    );
  }

  get documentTextContentRanges(): Range[] {
    if (!this.initialized) {
      return [];
    }
    if (this._internalDocumentTextRanges?.length) {
      return this._internalDocumentTextRanges;
    }
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('No document text content found');
    }
    const childrenToUse: Range[] = [];
    const chunkContainers = this.documentTextContent.querySelectorAll('.rw-chunk-container');
    for (const chunkContainer of chunkContainers) {
      let topLevelDocumentChildren = Array.from(chunkContainer.childNodes);
      // if we only have one main element, and that element has children, most likely that's the element we are interested in
      if (chunkContainer.children.length === 1) {
        topLevelDocumentChildren = Array.from(chunkContainer.children[0].childNodes)
          .filter((node) => !IGNORED_DOCUMENT_CHILD_NODE_TAGS.has(node.nodeName));
      }
      for (const child of topLevelDocumentChildren) {
        if (isNodeAnHTMLElement(child)) {
          const range = new Range();
          if (child.getBoundingClientRect().height > 0 || child.getBoundingClientRect().width > 0) {
            range.selectNode(child);
            childrenToUse.push(range);
          }
        } else if (isTextNode(child)) {
          const range = new Range();
          range.selectNode(child);
          if (range.getClientRects().length > 0) {
            childrenToUse.push(range);
          }
        }
      }
    }

    this._internalDocumentTextRanges = childrenToUse;
    return childrenToUse;
  }

  doesElementContainDirectTextNodes(element: Element) {
    for (const child of element.childNodes) {
      if (isTextNode(child) && child.textContent && child.textContent.replace('\n', '').length >= 1) {
        return true;
      }
    }
    return false;
  }

  getEfficientClientRectFromRange(range: Range) {
    const firstElement = this.getFirstElementInRange(range);
    if (firstElement instanceof Element) {
      return firstElement.getBoundingClientRect();
    }
    return range.getBoundingClientRect();
  }

  getRangeAtY(yCoord: number, returnClosestElement = false): Range | null {
    if (!this.documentRoot) {
      throw new ScrollingManagerError('No document root found');
    }
    const allRanges = this.documentTextContentRanges;
    let left = 0;
    let right = allRanges.length - 1;
    let middleRange = null;
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      middleRange = allRanges[mid];
      const clientRect = this.getEfficientClientRectFromRange(middleRange);

      const absoluteTopOfElement =
        clientRect.top + this.getScrollingElementTop();
      const absoluteBottomOfElement =
        clientRect.bottom + this.getScrollingElementTop();

      if (absoluteTopOfElement <= yCoord && absoluteBottomOfElement >= yCoord) {
        return middleRange;
      }
      if (absoluteBottomOfElement < yCoord) {
        // this range is above the ycoord
        left = mid + 1;
      } else if (absoluteTopOfElement > yCoord) {
        // this range is below the ycoord
        right = mid - 1;
      } else {
        // this else case makes no sense
        // for us to arrive here the element must
        // have a bottom coord below the ycoord and a top above ycoord
        // but it didn't intersect... ?
        // In any case I wouldn't want the loop to run forever so returning null here is ok
        return null;
      }
    }
    // If we got here that means we didnt get a super precise value but
    // we can still return the last seen middle range in our search
    if (middleRange && returnClosestElement) {
      return middleRange;
    }
    return null;
  }

  getAllRangesInRect(pageRect: PageRect, verticalMargin: number): Range[] {
    const viewportWidth = this.window.innerWidth;
    const relativePageRect = {
      top: pageRect.top - this.getScrollingElementTop() - verticalMargin,
      bottom: pageRect.bottom - this.getScrollingElementTop() + verticalMargin,
      left: 0,
      right: viewportWidth,
    };
    return getVisibleElementsWithinRectBounds(this.documentTextContentRanges, relativePageRect);
  }

  getTextContentFromElementOrRange(elementOrRange: Element | Range) {
    let textContent = '';
    if (elementOrRange instanceof Element) {
      textContent = elementOrRange.textContent ?? '';
    }
    if (elementOrRange instanceof Range) {
      textContent = elementOrRange.toString();
    }
    return textContent;
  }

  getFirstElementInRange(range: Range): Node {
    return range.startContainer.childNodes[range.startOffset];
  }

  getNumberOfWordsInRect(rect: PageRect) {
    const visibleRanges = this.getAllRangesInRect(rect, 0);
    if (!visibleRanges.length) {
      return 0;
    }

    const firstRange = visibleRanges[0];
    const lastRange = visibleRanges[visibleRanges.length - 1];

    let totalWordCount = 0;

    // First we need to see if the firstRange gets cut-off at the top
    const scrollTopOfFirstElement = this.getAbsoluteScrollTopOfElement(firstRange);
    const firstElementHeight = firstRange.getBoundingClientRect().height;
    // We want to see what % of the element is visible within the provided rect
    // (e.g it might be half above and half inside the rect)
    const firstElementDistanceFromRectTop = rect.top - scrollTopOfFirstElement;
    let percentOfFirstElementVisible = 1;
    if (firstElementDistanceFromRectTop > 0) {
      // the first element is cut-off, figure out by how much
      const visibleHeight = Math.min(Math.max(0, firstElementHeight - firstElementDistanceFromRectTop), this.pageHeight);
      percentOfFirstElementVisible = Math.min(1, visibleHeight / firstElementHeight);
    }

    const firstElementTextContent = this.getTextContentFromElementOrRange(firstRange);

    totalWordCount += firstElementTextContent.split(' ').length * percentOfFirstElementVisible;

    for (let i = 1; i < visibleRanges.length - 1; i++) {
      const textContent = this.getTextContentFromElementOrRange(visibleRanges[i]);
      totalWordCount += textContent.split(' ').length;
    }


    if (lastRange === firstRange) {
      return Math.round(totalWordCount);
    }
    // Now we do the same with the last element

    const scrollTopOfLastElement = this.getAbsoluteScrollTopOfElement(lastRange);
    const lastElementHeight = lastRange.getBoundingClientRect().height;
    let percentOfLastElementVisible = 1;
    const lastElementDistanceFromBottom = rect.bottom - Math.max(scrollTopOfLastElement, this.getScrollingElementTop());
    if (lastElementDistanceFromBottom < 0) {
      percentOfLastElementVisible = 0;
    } else {
      percentOfLastElementVisible = Math.min(1, lastElementDistanceFromBottom / lastElementHeight);
    }

    const lastElementTextContent = this.getTextContentFromElementOrRange(lastRange);
    totalWordCount += lastElementTextContent.split(' ').length * percentOfLastElementVisible;
    return Math.round(totalWordCount);
  }

  getNumberOfWordsBetweenReadingPositions(previousReadingPosition: LenientReadingPosition, newReadingPosition: LenientReadingPosition): number {
    throw new ScrollingManagerError('getNumberOfWordsBetweenReadingPositions must be implemented in child class');
  }

  getNumberOfWordsOnScreen() {
    if (!this.documentTextContent) {
      return 0;
    }
    const rect = {
      top: this.getScrollingElementTop(),
      bottom: this.getScrollingElementTop() + this.window.innerHeight,
      bottomPageDividerHeight: 0,
    };
    return Math.min(2000, this.getNumberOfWordsInRect(rect));
  }

  protected async _forceChunkContentLoaded(container: ChunkContainerElement): Promise<void> {
    const surroundingContainers = [
      container.previousElementSibling,
      container,
      container.nextElementSibling,
    ].filter(isChunkContainer);
    // forceContentLoadForChunk is a no-op if chunk contents are already loaded, in which case this will be instant.
    await Promise.all(surroundingContainers.map(
      (container) => forceContentLoadForChunk(container.dataset.chunkId),
    ));
    this.onContentChanged();
  }

  protected _temporarilyForceCenteredElement(element: HTMLElement, scrollDelta: number) {
    this.currentCenteredElementInfo = {
      element,
      scrollDelta,
    };
    this.updatingCenterElementDisabled = true;
    setTimeout(this._debouncedStartUpdatingCenterElement.bind(this), 2000);
  }
}
